<template>
  <b-modal
    id="add-or-update-event-center"
    :visible="isAddOrUpdateActive"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    size="lg"
    @hidden="resetForm"
    @hide="resetForm"
    @change="(val) => $emit('update:is-add-or-update-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            {{ isUpdate ? 'Update' : 'Add New' }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="update_modal_form"
        >
          <b-form
            class="my-1"
            @submit.prevent="handleSubmit(onCreateOrUpdate)"
            @reset.prevent="resetForm"
          >

            <b-alert
              v-if="errorMessage"
              variant="danger"
              :show="true"
            >
              <div class="alert-body font-small-2">
                <b-card-text class="mb-0">
                  {{ errorMessage }}
                </b-card-text>
              </div>
            </b-alert>
            
            <validation-provider
              #default="validationContext"
              name="Job title"
              rules="required"
            >
              <b-form-group
                label-for="title"
              >
                <template #label>
                  <span>Job title<sup style="color: tomato">*</sup></span>
                </template>

                <b-form-input
                  id="title"
                  v-model="new_data.title"
                  autofocus
                  trim
                  placeholder="Add the title you are hiring for"
                  :disabled="disableForm"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Job description"
              rules="required"
            >
              <b-form-group
                label-for="job_content"
              >
                <template #label>
                  <span>Job description<sup style="color: tomato">*</sup></span>
                </template>

                <quill-editor
                  id="job_content"
                  v-model="new_data.content"
                  :options="snowOption"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Workplace type"
              rules="required"
            >
              <b-form-group
                label-for="work_place_type"
              >
                <template #label>
                  <span>Workplace type<sup style="color: tomato">*</sup></span>
                </template>

                <b-form-select
                  id="work_place_type"
                  v-model="new_data.work_place_type"
                  :options="work_place_types"
                  placeholder="Please select"
                  :disabled="disableForm"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Job type"
              rules="required"
            >
              <b-form-group
                label-for="job_type"
              >
                <template #label>
                  <span>Job type<sup style="color: tomato">*</sup></span>
                </template>

                <b-form-select
                  id="job_type"
                  v-model="new_data.job_type"
                  :options="job_types"
                  placeholder="Please select"
                  :disabled="disableForm"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Job category(s)"
              rules="required"
            >
              <b-form-group
                label-for="job_interests"
              >
                <template #label>
                  <span>Job category(s)<sup style="color: tomato">*</sup></span>
                </template>

                 <v-select
                    id="job_interests"
                    v-model="new_data.job_interests"
                    multiple
                    :close-on-select="false"
                    :loading="loading"
                    :clearable="false"
                    :reduce="val => val.value"
                    :options="jobCategoryOptions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Job coverage"
              rules="required"
            >
              <b-form-group
                label-for="job_coverage"
              >
                <template #label>
                  <span>Job coverage<sup style="color: tomato">*</sup></span>
                </template>

                <b-form-radio-group
                  id="job_coverage"
                  v-model="new_data.coverage"
                  name="job_coverage"
                  stacked
                >
                  <b-form-radio style="margin-bottom: 5px;" value="all">All matched candidates</b-form-radio>
                  <b-form-radio style="margin-bottom: 5px;" value="country">Only candidates within country</b-form-radio>
                  <b-form-radio style="margin-bottom: 5px;" value="region">Only candidates within region</b-form-radio>
                  <b-form-radio style="margin-bottom: 5px;" value="city">Only candidates within city></b-form-radio>
                </b-form-radio-group>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="showCountry"
              #default="validationContext"
              name="Country"
              rules="required"
            >
              <b-form-group
                label-for="country"
              >
                <template #label>
                  <span>Country<sup style="color: tomato">*</sup></span>
                </template>

                <v-select
                  id="country"
                  v-model="new_data.country"
                  :loading="loading"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countryOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="showRegion"
              #default="validationContext"
              name="Region"
              rules="required"
            >
              <b-form-group
                label-for="region"
              >
                <template #label>
                  <span>Region<sup style="color: tomato">*</sup></span>
                </template>

                <v-select
                  id="region"
                  v-model="new_data.region"
                  :loading="loading"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="regionOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="showCity"
              #default="validationContext"
              name="City"
              rules="required"
            >
              <b-form-group
                label-for="city"
              >
                <template #label>
                  <span>City<sup style="color: tomato">*</sup></span>
                </template>

                <v-select
                  id="city"
                  v-model="new_data.city"
                  :loading="loading"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cityOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Job status"
              rules="required"
            >
              <b-form-group
                label-for="status"
              >
                <template #label>
                  <span>Job status<sup style="color: tomato">*</sup></span>
                </template>

                <b-form-select
                  id="status"
                  v-model="new_data.status"
                  :options="status_options"
                  placeholder="Please select"
                  :disabled="disableForm"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="disableForm"
              >
                {{ isUpdate ? 'Update' : 'Add' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BOverlay, BSidebar, BForm, BFormGroup, BFormInput, 
  BFormTextarea, BFormInvalidFeedback, BButton, BFormSelect,
  BAlert, BCardText, BModal, BFormCheckbox, BFormDatepicker, BFormRadioGroup, BFormRadio
} from 'bootstrap-vue'

import { required } from '@validations'
import { get, cloneDeep, omit } from 'lodash';
import { quillEditor } from 'vue-quill-editor';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const rawFormData = {
  title: "",
  content: '<p>Tips: Provide a summary of the role, what success in the position looks like, and how this role fits into the organization overall.</p><p><br></p><p><strong>Responsibilities</strong></p><p>[Be specific when describing each of the responsibilities. Use gender-neutral, inclusive language.]</p><p>Example: Determine and develop user requirements for systems in production, to ensure maximum usability</p><p><br></p><p><strong>Qualifications</strong></p><p>[Some qualifications you may want to include are Skills, Education, Experience, or Certifications.]</p><p>Example: Excellent verbal and written communication skills</p>',
  work_place_type: "",
  job_type: "",
  job_interests: [],
  country: 2300660,
  region: "",
  city: "",
  coverage: 'all',
  status: "draft"
}

export default {
  components: {
    BForm,
    BAlert,
    BModal,
    BButton,
    vSelect,
    BOverlay,
    BSidebar,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormSelect,
    BFormTextarea,
    BFormCheckbox,
    BFormRadioGroup, 
    BFormDatepicker,
    BFormInvalidFeedback,

    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddOrUpdateActive',
    event: 'update:is-add-or-update-active',
  },
  props: {
    isAddOrUpdateActive: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      default: null,
      required: false
    },
  },
  data() {
    return {
      required,

      step: 0,
      loading: false,
      errorMessage: "",
      job_types: [
        "Full-time",
        "Part-time",
        "Contract",
        "Self-employed",
        "Temporary",
        "Freelance",
        "Internship",
        "Remote",
        "Volunteer"
      ].map(type => ({ text: type, value: type })),
      work_place_types: [
        "On-site",
        "Hybrid",
        "Remote",
      ].map(type => ({ text: type, value: type })),
      status_options: [
        { text: 'Draft', value: 'draft' },
        { text: 'Live', value: 'live' }
      ],

      countryOptions: [],
      regionOptions: [],
      cityOptions: [],

      new_data: cloneDeep(rawFormData),
      snowOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'code-block'],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ['clean'] // remove formatting button
          ]
        },
        placeholder: 'Provide a summary of the role, what success in the position looks like, and how this role fits into the organization overall.',
        theme: 'snow',
      }
    }
  },
  computed: {
    loaded() {
      return this.step === 3
    },
    isUpdate(){
      return !!this.updateData
    },
    disableForm(){
      return false
    },
    showCountry() {
      return ['country', 'region', 'city'].includes(this.new_data.coverage)
    },
    showRegion() {
      return ['region', 'city'].includes(this.new_data.coverage)
    },
    showCity() {
      return ['city'].includes(this.new_data.coverage)
    }
  },
  watch: {
    updateData: {
      handler(updateData){
        if (updateData){
          this.new_data = {
            _id: updateData._id,
            title: updateData.title,
            content: updateData.content,
            job_type: updateData.job_type,
            work_place_type: updateData.work_place_type,
            job_interests: updateData.job_interests,
            country: updateData.country,
            region: updateData.region,
            city: updateData.city,
            coverage: updateData.coverage,
            status: updateData.status,
          }
        }
      },
      immediate: true,
      deep: true
    },
    'new_data.country': {
      handler(changed){
        if (changed){
          this.fetchGeoData(changed, this.onRegionsFetch);
        }
      },
      immediate: true
    },
    'new_data.region': {
      handler(changed){
        if (changed){
          this.fetchGeoData(changed, this.onCitiesFetch);
        }
      },
      immediate: true
    },
  },
  created(){
    this.fetchCountries();
  },
  methods: {
    async fetchCountries() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchCountries({ criteria: 'all' });
        const { data } = request.data;
        this.countryOptions = data.map(country => ({
          label: country.country,
          value: country.geo_name_id
        }));

        if (this.loaded){
          this.new_data.region = '';
          this.new_data.city = '';
        } else if (!this.loaded){
          this.step += 1;
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchGeoData(geo_id, cb) {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchGeoData({ geo_id });
        const { data } = request.data;
        cb(data)
        if (!this.loaded){
          this.step += 1;
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onCreateOrUpdate(){
      try {
        this.loading = true;
        this.errorMessage = "";

        const success = await this.$refs.update_modal_form.validate();
        if (!success){
          return;
        }

        const messages = {
          update: {
            title: "Confirm Update",
            text: "Proceed to update job post?",
            icon: "warning"
          },
          new: {
            title: "Create",
            text: "Proceed to create new job post?",
            icon: "info"
          }
        }

        /* eslint-disable no-nested-ternary */
        const message = this.isUpdate ? messages.update : messages.new;
        const { title, text, icon } = message;

        const result = await this.$swal({
          title,
          text,
          icon,
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        if (!result.isConfirmed) {
          return;
        }
        
        if (this.isUpdate){
          const id = this.new_data._id
          const update_data = omit(this.new_data, "_id");
          await this.useJwt().providerService.updateJob(id, update_data);
        } else {
          await this.useJwt().providerService.postJob(this.new_data);
        }

        this.$emit("refetchData");
        this.$emit('update:is-add-or-update-active', false);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `${this.isUpdate ? 'Updated' : 'Added'} successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    onRegionsFetch(data) {
      this.cityOptions = [];
      this.townOptions = [];

      this.regionOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));
      // console.log('region fetched', this.loaded, this.step)
      if (this.loaded){
        this.new_data.region = '';
        this.new_data.city = '';
      }
    },
    onCitiesFetch(data) {
      this.townOptions = [];
      
      this.cityOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));
      // console.log('cities fetched', this.loaded, this.step)
      if (this.loaded){
        this.new_data.city = '';
      }
    },
    resetForm() {
      this.$emit("resetUpdateData")
      this.step = 1;
      this.new_data = cloneDeep(rawFormData);
      if (this.$refs.update_modal_form) {
        this.$refs.update_modal_form.reset()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

#add-or-update-event-center {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
