<template>
  <b-overlay :show="loading" class="mt-5">
    <add-or-update-job-post-modal
      :is-add-or-update-active.sync="isAddOrUpdateActive"
      :update-data="updateData"
      @refetchData="fetch"
      @resetUpdateData="resetUpdateData"
    />
    
    <list-table
      :records="tableData"
      :columns="tableColumns"
      :search-filter.sync="search"
      :total-records="totalRecords"
      :current-page-number="currentPage"
      :show-search="true"
      :show-export-button="false"
      :show-filter-by-default="true"
      @row-clicked="onRowClicked"
    >
      <template #action_button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="isAddOrUpdateActive = true"
        >
          <span class="align-middle text-lg">Post Job</span>
        </b-button>
      </template>

      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ getUserStatusText(data.item.status) }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item v-if="getValueFromSource(data, 'item.status') === 'live'" :to="{ name: 'provider-preview-post', params: { job_id: data.item._id } }">
            <feather-icon icon="EyeIcon" />
            <span class="align-middle ml-50">Preview</span>
          </b-dropdown-item>

          <b-dropdown-item :to="{ name: 'provider-post-candidates', params: { job_id: data.item._id } }">
            <feather-icon icon="EyeIcon" />
            <span class="align-middle ml-50">View CV Submissions</span>
          </b-dropdown-item>

          <b-dropdown-item @click="onEdit(data.item)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

           <b-dropdown-item @click="onDelete(data.item._id)">
            <feather-icon icon="DeleteIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </list-table>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ListTable from "@core/components/ListTable/ListTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddOrUpdateJobPostModal from "@/@core/components/shared/provider/AddOrUpdateJobPostModal.vue";

import { debounce, get } from "lodash";
import { formatDate } from "@core/utils/utils";
import { BOverlay, BLink, BButton, BModal, BRow, BCol, BTooltip, BAvatar, BMedia, BBadge, BDropdown, BDropdownItem, } from "bootstrap-vue";

const watchHandler = {
  handler(){
    this.debounceFetch()
  },
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1;
    this.debounceFetch()
  },
  immediate: false
}

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BModal,
    BMedia,
    BBadge,
    BButton,
    vSelect,
    BAvatar,
    BTooltip,
    BOverlay,
    ListTable,
    BDropdown,
    BDropdownItem,
    AddOrUpdateJobPostModal
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      tableData: [],

      search: "",
      updateData: null,
      debounceFetch: null,
      isAddOrUpdateActive: false,

      tableColumns: [
        { key: 'title', label: 'Title', sortable: false },
        { key: 'job_type', label: 'Type', sortable: false },
        { key: 'work_place_type', label: 'Workplace', sortable: false },
        { key: 'applications', sortable: false },
        { key: 'status', sortable: false },
        { key: 'created', sortable: false, formatter: val => `${formatDate(val, 'MMM, yyyy')}`, class: 'width-200' },
        { key: 'actions' },
      ]
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    currentPage: watchHandler,
    search: watchHandlerRequiresPageReset,
    perPage: watchHandlerRequiresPageReset,
  },
  created() {
    this.debounceFetch = debounce(this.fetch, 500);

    const {
      search,
      page = 1,
      limit = this.perPage
    } = this.$route.query;

    this.search = search;
    this.perPage = +limit;
    this.currentPage = +page;
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;

        const query = {
          limit: this.perPage,
          search: this.search,
          page: this.currentPage,
        }
        await this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().providerService.fetchJobs(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords;
        this.perPage = pagination.limit;
        this.tableData = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onDelete(id){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Delete?',
          text: "Do you want to delete this job?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        await this.useJwt().providerService.deleteJob(id);
        await this.fetch()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Job deleted successfully'
          },
        });
      } catch (error) {
        const error_message = get(error, "response.statusText") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    onEdit(update){
      this.updateData = update;
      this.isAddOrUpdateActive = true;
    },
    resetUpdateData(){
      if (this.updateData){
        this.updateData = null
      }
    },
    onRowClicked(rowItem){
      if (rowItem.status !== 'live'){
        return;
      }
      
      this.$router.push({ name: 'provider-post-candidates', params: { job_id: rowItem._id } })
      .catch(() => {})
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

#add-or-update-event-center {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

